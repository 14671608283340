$xs-breakpoint: 480px;
$sm-breakpoint: 768px;
$md-breakpoint: 1024px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1600px;

$color-violet: #3b2053;
$color-blue: #3a84f0;
$color-yellow: #fadf41;

$color-twitter: #58b0df;
$color-twitter-like: #f91880;
$color-twitter-retweet: #2ed573;

$color-discord: #6659de;

$color-success: #53cc75;
$color-info: #3a84f0;
$color-warning: #ce8605;
$color-danger: #e2442f;
$color-success-light: #c2f3d6;
$color-info-light: #ceeafa;
$color-warning-light: #fae1b2;
$color-danger-light: #ffe1e3;

$color-darker-gray: #1f1f1f;
$color-dark-gray: #242424;
$color-gray: #2f2f2f;
$color-light-gray: #343434;
$color-lighter-gray: #3f3f3f;
$color-lighten-gray: #444444;

$main-bg-color: $color-darker-gray;

$text-color: #e8e8e8;
