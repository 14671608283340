@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 6rem);
  margin: 3rem;
  display: flex;
}

@media (width <= 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 400px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (width <= 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Cees Hand;
  src: url("https://cdn.piratesquadnft.com/clients/oogy-blast/fonts/Cees_Hand/Cees_Hand_Regular.ttf");
}

@font-face {
  font-family: Supersonic Rocketship;
  src: url("https://cdn.piratesquadnft.com/clients/oogy-blast/fonts/Supersonic_Rocketship/Supersonic_Rocketship.ttf");
}

@font-face {
  font-family: More Sugar;
  src: url("https://cdn.piratesquadnft.com/clients/oogy-blast/fonts/More_Sugar/More_Sugar_Thin.otf");
}

html {
  scroll-behavior: smooth;
}

body {
  color: #e8e8e8;
  background-color: #1f1f1f;
  justify-content: center;
  margin: 0;
  display: flex;
}

#react {
  width: 100vw;
  height: 100vh;
}

:root {
  --sm-breakpoint: 768px;
}

:disabled {
  cursor: not-allowed;
}

::-webkit-scrollbar-track {
  background: none;
}

.show-element {
  animation: 1s 3 pulse;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #ffffffb3;
  }

  100% {
    box-shadow: 0 0 0 10px #fff0;
  }
}

.overflow-hidden {
  overflow: hidden;
}

@media screen and (width <= 768px) {
  .phone-without-padding {
    padding: 0 !important;
  }
}

.text-info {
  color: #3a84f0;
}

.text-danger {
  color: #e2442f;
}

.text-success {
  color: #53cc75;
}

.text-warning {
  color: #ce8605;
}

.tick-flip-panel {
  color: #2f2f2f !important;
  background-color: #e8e8e8 !important;
}

.tick-group {
  text-align: center;
  margin: 0 .5em;
}

.transition-enter {
  opacity: 0;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity .2s;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity .2s;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fe49bb #dfe9eb;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #dfe9eb;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

::-webkit-scrollbar-thumb {
  background-color: #fe49bb;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fe8dd5;
}

::-webkit-scrollbar-thumb:active {
  background-color: #fe2eb2;
}

._4QO8gW_button {
  white-space: nowrap;
  color: #fff;
  text-shadow: 0 2px #000000d4;
  letter-spacing: .05rem;
  text-transform: uppercase;
  background: linear-gradient(#e75ab3 0% 50%, #db43a3 50% 100%);
  border: 1px solid #000;
  border-radius: 999rem;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem 2rem;
  font-family: Supersonic Rocketship;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

._4QO8gW_button * {
  pointer-events: none;
}

._4QO8gW_button._4QO8gW_small {
  height: 3rem;
  padding: .5rem 1rem;
  font-size: 1.3rem;
}

._4QO8gW_button._4QO8gW_xsmall {
  height: 2rem;
  padding: .25rem .5rem;
  font-size: .9rem;
}

._4QO8gW_button._4QO8gW_xxsmall {
  height: 1.5rem;
  padding: .25rem .5rem;
  font-size: .85rem;
}

._4QO8gW_button:hover:not(:disabled), ._4QO8gW_button._4QO8gW_selected {
  cursor: pointer;
  background: linear-gradient(#ea6cbb 0% 50%, #de54ab 50% 100%);
}

._4QO8gW_button:disabled {
  cursor: not-allowed;
  color: #fffa;
  text-shadow: none;
  background: linear-gradient(#d7d7d7 0% 50%, #b5b4b5 50% 100%);
}

._4QO8gW_button._4QO8gW_loading {
  cursor: not-allowed;
  pointer-events: none;
}

._4QO8gW_button._4QO8gW_gray {
  color: #fff;
  background: linear-gradient(#d7d7d7 0% 50%, #b5b4b5 50% 100%);
}

._4QO8gW_button._4QO8gW_gray:hover:not(:disabled), ._4QO8gW_button._4QO8gW_gray._4QO8gW_selected {
  background: linear-gradient(#e1e1e1 0% 50%, #bfbebf 50% 100%);
}

._4QO8gW_button._4QO8gW_blue {
  color: #fff;
  background: linear-gradient(#669af7 0% 50%, #526be4 50% 100%);
}

._4QO8gW_button._4QO8gW_blue:hover:not(:disabled), ._4QO8gW_button._4QO8gW_blue._4QO8gW_selected {
  background: linear-gradient(#79a7f8 0% 50%, #647ae7 50% 100%);
}

._4QO8gW_button._4QO8gW_yellow {
  color: #fff;
  background: linear-gradient(#eadb5f 0% 50%, #decf45 50% 100%);
}

._4QO8gW_button._4QO8gW_yellow:hover:not(:disabled), ._4QO8gW_button._4QO8gW_yellow._4QO8gW_selected {
  background: linear-gradient(#ecdf71 0% 50%, #e1d356 50% 100%);
}

._4QO8gW_button._4QO8gW_violet {
  color: #fff;
  background: linear-gradient(#8f43db 0% 50%, #833acb 50% 100%);
}

._4QO8gW_button._4QO8gW_violet:hover:not(:disabled), ._4QO8gW_button._4QO8gW_violet._4QO8gW_selected {
  background: linear-gradient(#9954de 0% 50%, #8d4acf 50% 100%);
}

._4QO8gW_button._4QO8gW_pink {
  color: #fff;
  background: linear-gradient(#ffb8e5 0% 50%, #f996d5 50% 100%);
}

._4QO8gW_button._4QO8gW_pink:hover:not(:disabled), ._4QO8gW_button._4QO8gW_pink._4QO8gW_selected {
  background: linear-gradient(#ffccec 0% 50%, #faa9dd 50% 100%);
}

._4QO8gW_button._4QO8gW_shaded-black {
  color: #fff;
  background: linear-gradient(#8d8142 0%, #141313 50%, #8d8142 100%);
}

._4QO8gW_button._4QO8gW_shaded-black:hover:not(:disabled), ._4QO8gW_button._4QO8gW_shaded-black._4QO8gW_selected {
  background: linear-gradient(#9b8e49 0%, #1e1d1d 50%, #9b8e49 100%);
}

._4QO8gW_button._4QO8gW_success {
  color: #fff;
  background-color: #53cc75;
}

._4QO8gW_button._4QO8gW_success:hover:not(:disabled), ._4QO8gW_button._4QO8gW_success._4QO8gW_selected {
  background-color: #36b65a;
}

._4QO8gW_button._4QO8gW_warning {
  color: #fff;
  background-color: #ce8605;
}

._4QO8gW_button._4QO8gW_warning:hover:not(:disabled), ._4QO8gW_button._4QO8gW_warning._4QO8gW_selected {
  background-color: #9c6604;
}

._4QO8gW_button._4QO8gW_no-color {
  color: #e8e8e8;
  background-color: #0000;
}

._4QO8gW_button._4QO8gW_no-color:hover:not(:disabled), ._4QO8gW_button._4QO8gW_no-color._4QO8gW_selected {
  background-color: #343434;
}

._4QO8gW_button._4QO8gW_no-color:disabled {
  cursor: not-allowed;
  color: #aaa !important;
}

._4QO8gW_button._4QO8gW_no-color:disabled svg {
  color: #aaa !important;
}

._4QO8gW_button._4QO8gW_no-padding {
  padding: 0;
}

._4QO8gW_button._4QO8gW_icon {
  gap: 1rem;
  width: auto;
}

._4QO8gW_button ._4QO8gW_content {
  align-items: center;
  gap: .5rem;
  display: flex;
}

._4QO8gW_button ._4QO8gW_content._4QO8gW_floating svg {
  margin: auto;
  position: absolute;
  inset: 0;
}

._4QO8gW_button ._4QO8gW_content._4QO8gW_floating ._4QO8gW_children {
  display: none;
}

.clG-nq_input {
  box-sizing: border-box;
  color: #e8e8e8;
  background-color: #0000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  justify-content: center;
  align-items: center;
  padding: .5rem 1.5rem;
  font-family: More Sugar;
  font-size: 1rem;
  line-height: 2rem;
  display: flex;
}

.clG-nq_input::placeholder {
  color: #d2cff0;
  font-size: .9em;
}

.clG-nq_input:focus {
  outline: none;
}

._tFz-q_modal {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-family: More Sugar;
  font-size: 24px;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

._tFz-q_modal ._tFz-q_background {
  z-index: 102;
  position: absolute;
  inset: 0;
}

._tFz-q_modal ._tFz-q_container {
  z-index: 103;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

._tFz-q_modal ._tFz-q_container._tFz-q_full-size {
  width: 100%;
  height: 100%;
}

._tFz-q_modal ._tFz-q_container._tFz-q_image img {
  width: 50%;
  max-width: 300px;
}

._tFz-q_modal._tFz-q_no-blur {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

@media screen and (width >= 768px) {
  ._tFz-q_modal {
    font-size: 32px;
  }
}

._tFz-q_modal._tFz-q_loading-modal {
  z-index: 100;
}

._tFz-q_modal ._tFz-q_loading-content {
  color: #fff;
  text-shadow: 4px 3px #444;
  text-align: center;
  z-index: 101;
  font-weight: 700;
}

._tFz-q_modal ._tFz-q_window {
  box-sizing: border-box;
  word-wrap: break-word;
  pointer-events: all;
  background-color: #3b2053;
  flex-direction: column;
  width: calc(100% - 2rem);
  max-width: calc(480px - 2rem);
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 10rem);
  padding-top: 3rem;
  font-size: 1.5rem;
  display: flex;
  position: relative;
  box-shadow: 0 8px 16px #1119;
}

._tFz-q_modal ._tFz-q_window:after {
  content: "";
  text-align: center;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-bottom.png");
  background-size: contain;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -5px;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_title {
  text-align: center;
  box-sizing: border-box;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 3.5rem;
  padding: 1rem;
  font-family: Cees Hand;
  position: absolute;
  top: -3px;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_title ._tFz-q_cross {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_title ._tFz-q_cross:hover {
  cursor: pointer;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_content {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  font-size: 1.25rem;
  display: flex;
  overflow: hidden auto;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_content ._tFz-q_input {
  width: 100%;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_footer {
  padding: 1rem;
  display: flex;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_footer button {
  width: 100%;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_footer button:first-child:nth-last-child(2):first-child, ._tFz-q_modal ._tFz-q_window ._tFz-q_footer button:first-child:nth-last-child(2) ~ button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._tFz-q_modal ._tFz-q_window ._tFz-q_footer button:first-child:nth-last-child(2):last-child, ._tFz-q_modal ._tFz-q_window ._tFz-q_footer button:first-child:nth-last-child(2) ~ button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._tFz-q_modal-enter {
  opacity: 0;
  transform: scale(.95);
}

._tFz-q_modal-enter-active {
  opacity: 1;
  transition: opacity .15s ease-out, transform .15s ease-out;
  transform: translateX(0);
}

._tFz-q_modal-exit {
  opacity: 1;
}

._tFz-q_modal-exit-active {
  opacity: 0;
  transition: opacity .15s ease-in, transform .15s ease-in;
  transform: scale(.95);
}

.HdTl1G_hub-layout {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  font-family: Cees Hand;
  display: flex;
  position: relative;
}

.HdTl1G_hub-layout .HdTl1G_container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.HdTl1G_hub-layout .HdTl1G_buttons {
  color: #333;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  display: flex;
}

.HdTl1G_hub-layout .HdTl1G_buttons p {
  font-size: 32px;
}

.HdTl1G_hub-layout .HdTl1G_buttons p .HdTl1G_purple {
  color: #3b2053;
}

.HdTl1G_hub-layout .HdTl1G_buttons small {
  font-size: 18px;
}

.HdTl1G_hub-layout .HdTl1G_custom-toast img {
  width: 10px;
  height: 10px;
}

.vsoQtq_wallet-layout {
  width: 100%;
  height: 100%;
}

.AtHbqW_toggle {
  width: 2em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.AtHbqW_toggle .AtHbqW_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.AtHbqW_toggle .AtHbqW_switch input:checked + .AtHbqW_slider {
  background-color: #3a84f0;
}

.AtHbqW_toggle .AtHbqW_switch input:focus + .AtHbqW_slider {
  box-shadow: 0 0 1px #3a84f0;
}

.AtHbqW_toggle .AtHbqW_switch input:checked + .AtHbqW_slider:before {
  transform: translateX(1em);
}

.AtHbqW_toggle .AtHbqW_switch .AtHbqW_slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.AtHbqW_toggle .AtHbqW_switch .AtHbqW_slider.AtHbqW_round {
  border-radius: 1em;
}

.AtHbqW_toggle .AtHbqW_switch .AtHbqW_slider.AtHbqW_round:before {
  border-radius: 50%;
}

.AtHbqW_toggle .AtHbqW_switch .AtHbqW_slider:before {
  content: "";
  background-color: #fff;
  width: .8em;
  height: .8em;
  transition: all .4s;
  position: absolute;
  bottom: .1em;
  left: .1em;
}

.yhrBHW_connect-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: Cees Hand !important;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
  box-sizing: border-box;
  color: #000;
  pointer-events: all;
  background-color: #fffc;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  width: 100vw;
  max-width: min(700px, 100vw - 2rem);
  height: 100vh;
  max-height: min(500px, 100vh - 2rem);
  padding: 1rem;
  display: flex;
  box-shadow: 0 8px 24px #1f1f1f;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal img {
  width: auto;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet {
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  display: flex;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_install-wallet {
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  font-family: Cees Hand;
  font-size: 32px;
  display: flex;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_install-wallet small {
  font-size: 18px;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_install-wallet a {
  color: #3a84f0;
  text-decoration: none;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_form {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  margin: 4rem;
  display: flex;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_form button {
  white-space: nowrap;
  color: #fff;
  text-shadow: 0 2px #000000d4;
  letter-spacing: .05rem;
  text-transform: uppercase;
  background: linear-gradient(#e75ab3 0% 50%, #db43a3 50% 100%);
  border: 1px solid #000;
  border-radius: 999rem;
  justify-content: center;
  align-items: center;
  width: 12rem;
  padding: .5rem 1rem;
  font-family: Supersonic Rocketship;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_form button i {
  display: none;
}

.yhrBHW_connect-modal-overlay .yhrBHW_connect-modal .yhrBHW_wallet .yhrBHW_form .yhrBHW_change-wallet button {
  background: linear-gradient(#d7d7d7 0% 50%, #b5b4b5 50% 100%);
}

.yhrBHW_connect-modal-overlay .yhrBHW_ledger-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 18px;
  font-weight: normal;
  display: flex;
}

.yhrBHW_connect-modal-overlay .yhrBHW_ledger-toggle .yhrBHW_toggle {
  pointer-events: none;
}

._4xkGq_dashboard {
  color: #e8e8e8;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  font-family: Cees Hand;
  font-size: 20px;
  display: flex;
  position: relative;
}

._4xkGq_dashboard._4xkGq_player-view {
  height: 100vh;
}

._4xkGq_dashboard._4xkGq_player-view ._4xkGq_container ._4xkGq_content {
  max-height: 100vh;
  padding: 0;
  overflow: hidden;
}

._4xkGq_dashboard ._4xkGq_container {
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
}

._4xkGq_dashboard ._4xkGq_container._4xkGq_limited-height {
  max-height: 100vh;
  overflow: hidden;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content {
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_permission {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
  height: 100%;
  display: flex;
  position: relative;
  box-shadow: 0 0 3rem #0007;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container {
  z-index: 10;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  display: flex;
  position: relative;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_connect-button {
  height: auto;
  padding: 1rem 4rem;
  font-size: 1.5rem;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 35px;
  font-size: 22px;
  display: block;
  position: relative;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms a {
  color: #fff;
  font-weight: bold;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms ._4xkGq_checkmark {
  background-color: #eee;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms:hover input ~ ._4xkGq_checkmark {
  background-color: #ccc;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms input:checked ~ ._4xkGq_checkmark {
  background-color: #fff;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms ._4xkGq_checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms input:checked ~ ._4xkGq_checkmark:after {
  display: block;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_button-container ._4xkGq_terms ._4xkGq_checkmark:after {
  border: 4px solid #de299b;
  border-width: 0 4px 4px 0;
  width: 5px;
  height: 12px;
  top: 3px;
  left: 9px;
  transform: rotate(45deg);
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_logo {
  z-index: 10;
  width: 75%;
  position: absolute;
  top: 10%;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_developed-by {
  color: #000;
  opacity: .85;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: smaller;
  display: flex;
  position: absolute;
  bottom: 1rem;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_developed-by img {
  width: 2.5rem;
}

._4xkGq_dashboard ._4xkGq_container ._4xkGq_content ._4xkGq_connection ._4xkGq_developed-by a {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

.DTLfIa_background {
  z-index: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
}

.DTLfIa_background .DTLfIa_gradient {
  mix-blend-mode: lighten;
  opacity: .6;
  z-index: 2;
  background: linear-gradient(#b84ebb00 0%, #c151c080 100%);
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 47%;
}

.DTLfIa_background .DTLfIa_bg-top, .DTLfIa_background .DTLfIa_bg-middle, .DTLfIa_background .DTLfIa_bg-bottom {
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}

.DTLfIa_background .DTLfIa_bg-top {
  z-index: 1;
  background-position: center;
  background-size: cover;
  height: 50%;
  top: 0;
}

.DTLfIa_background .DTLfIa_bg-middle {
  z-index: 2;
  background-position: bottom;
  background-size: contain;
  height: 50%;
  bottom: 40%;
}

.DTLfIa_background .DTLfIa_bg-bottom {
  z-index: 3;
  background-position: 0;
  background-size: cover;
  height: 46%;
  bottom: 0;
}

.DTLfIa_background.DTLfIa_high .DTLfIa_bg-middle {
  bottom: 58%;
}

.DTLfIa_background.DTLfIa_high .DTLfIa_bg-bottom {
  height: 65%;
}

.elvQeq_player {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
}

.elvQeq_player canvas {
  aspect-ratio: unset;
  max-width: 100%;
  max-height: 100%;
  width: 100% !important;
  height: 100% !important;
}

.elvQeq_player .elvQeq_screen-size {
  text-align: center;
  padding: 1rem;
}

.elvQeq_player .elvQeq_game {
  width: 100%;
  height: 100%;
  position: absolute;
}

.elvQeq_player .elvQeq_game.elvQeq_hidden {
  visibility: hidden;
}

.elvQeq_player .elvQeq_ui-container {
  color: #333;
  z-index: 1;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  width: min(480px, 100%);
  height: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.elvQeq_player .elvQeq_ui-container .elvQeq_main-ui {
  pointer-events: all;
  width: 100%;
  height: 100%;
  animation: .3s forwards elvQeq_fadeIn;
  display: none;
  box-shadow: 0 0 3rem #0007;
}

.elvQeq_player .elvQeq_ui-container .elvQeq_main-ui.elvQeq_fade-in {
  display: flex;
}

.elvQeq_player .elvQeq_ui-container .elvQeq_pause-menu, .elvQeq_player .elvQeq_ui-container .elvQeq_continue-menu {
  pointer-events: all;
}

.elvQeq_player .elvQeq_top-bar {
  z-index: 12;
}

.elvQeq_player .elvQeq_navigation {
  z-index: 10;
}

@keyframes elvQeq_fadeIn {
  0% {
    opacity: 0;
    display: flex;
  }

  100% {
    opacity: 1;
  }
}

.nVyzUq_continue-menu .nVyzUq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.nVyzUq_continue-menu .nVyzUq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.nVyzUq_continue-menu .nVyzUq_title img:hover {
  cursor: pointer;
}

.nVyzUq_continue-menu .nVyzUq_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_player {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 8rem;
  position: relative;
  rotate: -90deg;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_shadow {
  opacity: .5;
  background: #ec2727;
  border-radius: 100%;
  width: 136px;
  height: 36.615px;
  margin-top: 5rem;
  position: absolute;
  top: 0;
  bottom: 0;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_gems {
  display: flex;
  position: relative;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_gems .nVyzUq_error {
  color: #ff6262;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_gems img {
  height: 60%;
  position: absolute;
  left: 1rem;
}

.nVyzUq_continue-menu .nVyzUq_content .nVyzUq_gems > div {
  flex: 1;
}

.nVyzUq_continue-menu .nVyzUq_content button {
  width: min(18rem, 100%);
}

.wlS_9a_badge {
  color: #fff;
  text-shadow: 0 .2em #4a2d01;
  background: #ff891c;
  border: .2em solid #ffbe8f;
  border-radius: .7em;
  padding: .3em .5em;
  font-family: Supersonic Rocketship;
  font-size: 1em;
  font-weight: 400;
  display: inline-block;
  box-shadow: 0 .2em 0 .2em #4a2d01;
}

.wlS_9a_badge.wlS_9a_pink {
  background: #db43a3;
  border-color: #ff8fc5;
  box-shadow: 0 .2em 0 .2em #4b0931;
}

.wlS_9a_badge.wlS_9a_violet {
  background: #a265e0;
  border-color: #b592ff;
  box-shadow: 0 .2em 0 .2em #011a4a;
}

.wlS_9a_badge.wlS_9a_green {
  background: #62971e;
  border-color: #165124;
  box-shadow: 0 .2em 0 .2em #182716;
}

.C7mfTG_buy-chest .C7mfTG_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.C7mfTG_buy-chest .C7mfTG_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.C7mfTG_buy-chest .C7mfTG_title img:hover {
  cursor: pointer;
}

.C7mfTG_buy-chest .C7mfTG_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

.C7mfTG_buy-chest .C7mfTG_content button {
  width: min(100%, 18rem);
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_tile {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: 13px;
  align-items: center;
  gap: 2rem;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_tile img {
  filter: brightness(.1);
  width: auto;
  height: 80%;
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_description {
  color: #d2cff0;
  text-align: center;
  letter-spacing: -.05rem;
  font-family: More Sugar;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_description span {
  color: #fff;
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_gems {
  width: min(100%, 18rem);
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_gems .C7mfTG_error {
  color: #ff6262;
}

.C7mfTG_buy-chest .C7mfTG_content .C7mfTG_gems img {
  height: 100%;
  margin-right: 1rem;
}

.Adk8EW_rewards {
  flex-flow: wrap;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.Adk8EW_rewards.Adk8EW_absolute .Adk8EW_image-container {
  position: absolute;
  left: 0;
  right: 0;
}

.Adk8EW_rewards.Adk8EW_absolute .Adk8EW_text {
  position: absolute;
  bottom: .5rem;
}

.Adk8EW_rewards.Adk8EW_big .Adk8EW_reward {
  min-width: min(7.5rem, 25vw);
  min-height: min(9rem, 30vw);
}

.Adk8EW_rewards .Adk8EW_reward {
  border-radius: .5rem;
  min-width: min(6rem, 20vw);
  min-height: min(7rem, 25vw);
  position: relative;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_front, .Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
  display: block;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_front {
  transform: rotateY(0);
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_front.Adk8EW_special {
  box-shadow: none;
  background: none;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back {
  opacity: 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  width: 100%;
  height: 100%;
  padding: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(-180deg);
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_common {
  background: linear-gradient(#ded2f2fa 0%, #9c7ce1 100%);
  box-shadow: 0 4px #00000040, inset 0 4px 7px #514654db, 0 3px #423d48;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_rare {
  background: linear-gradient(#dcfac4fa 0%, #eee507 100%);
  box-shadow: 0 4px #00000040, inset 0 4px 7px #fdffe6db, 0 3px #b2b515;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_fabled {
  background: linear-gradient(#adfff5fa 0%, #8a1f7f 100%);
  box-shadow: 0 4px #00000040, inset 0 4px 7px #9e8a98db, 0 3px #eba9fb;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_weapon {
  background: linear-gradient(#fab994fa 0%, #a7ea87 100%);
  box-shadow: 0 4px #00000040, inset 0 4px 7px #ef9eebdb, 0 3px #bdd97f;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_special {
  box-shadow: none;
  background: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/metallic-bg.png") center / cover;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back.Adk8EW_tokens {
  background: linear-gradient(#e3ae6ffa 0%, #e3c26ffa .01%, #a86d28 100%);
  box-shadow: 0 4px #00000040, inset 0 4px 7px #ffe092db, 0 3px #7c4b1e;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back .Adk8EW_image-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back .Adk8EW_image-container .Adk8EW_image {
  width: 80%;
  max-height: 100%;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back .Adk8EW_image-container .Adk8EW_image.Adk8EW_generic {
  border-radius: .5rem;
  width: 100%;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back .Adk8EW_text {
  color: #fff;
  text-align: center;
  text-shadow: 0 .2rem #201728;
  letter-spacing: .05rem;
  font-family: Supersonic Rocketship;
  font-size: .8rem;
  font-weight: 400;
}

.Adk8EW_rewards .Adk8EW_reward > .Adk8EW_back .Adk8EW_rate {
  color: #555;
  text-align: center;
  letter-spacing: .05rem;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: #fffa;
  border-radius: .5rem;
  padding: .25rem .5rem;
  font-family: Supersonic Rocketship;
  font-size: .8rem;
  font-weight: 400;
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.Adk8EW_rewards .Adk8EW_reward.Adk8EW_show > .Adk8EW_front {
  transform: rotateY(180deg);
}

.Adk8EW_rewards .Adk8EW_reward.Adk8EW_show > .Adk8EW_back {
  opacity: 1;
  transform: rotateY(0);
}

.hulZaG_drop-table .hulZaG_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.hulZaG_drop-table .hulZaG_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.hulZaG_drop-table .hulZaG_title img:hover {
  cursor: pointer;
}

.hulZaG_drop-table .hulZaG_rewards {
  justify-content: center;
  height: 100%;
  overflow-y: auto;
}

.hulZaG_drop-table .hulZaG_description {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  padding-bottom: .5rem;
  font-family: More Sugar;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.hulZaG_drop-table .hulZaG_description span {
  color: #fe49bb;
}

.IhgY5q_hover:hover {
  cursor: pointer;
}

.IhgY5q_hide {
  opacity: 0 !important;
}

.IhgY5q_chest {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.IhgY5q_chest .IhgY5q_rewards-container {
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1rem;
  animation: .3s linear .2s forwards IhgY5q_appear;
  display: flex;
  position: absolute;
  inset: 0;
}

.IhgY5q_chest .IhgY5q_rewards-container .IhgY5q_rewards {
  pointer-events: all;
  justify-content: center;
  max-height: 40%;
  overflow-y: auto;
}

.IhgY5q_chest .IhgY5q_container {
  z-index: 4;
  flex-direction: column;
  height: 100%;
  padding: 4.5rem 1rem 3rem;
  display: flex;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_image {
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: -4%;
  left: 0;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle {
  z-index: 1;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container {
  z-index: 4;
  opacity: 0;
  justify-content: center;
  align-items: center;
  margin: 1rem auto auto;
  animation: .15s linear forwards IhgY5q_appear;
  display: flex;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container .IhgY5q_timer {
  color: #fff;
  z-index: 4;
  background: #70378b;
  border: .1rem solid #b54be7;
  border-radius: .15rem;
  width: min(50%, 8rem);
  height: min(1.7rem, 3.5vh);
  margin: auto;
  font-family: Cees Hand;
  font-size: min(1.5rem, 2.5vh);
  display: flex;
  position: absolute;
  top: 56%;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 .1rem #2f0b4ac7;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container .IhgY5q_timer .IhgY5q_countdown {
  flex: 1;
  justify-content: center;
  margin-top: .25rem;
  display: flex;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container .IhgY5q_badge {
  text-transform: uppercase;
  z-index: 3;
  font-size: min(1.5rem, 3vh);
  transition: all .5s;
  position: absolute;
  top: 34%;
  left: 15%;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container .IhgY5q_light {
  opacity: .3;
  width: 70%;
  margin: auto;
  transition: all .5s;
  animation: 3s linear infinite IhgY5q_rotating;
  position: absolute;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_chest-container .IhgY5q_image {
  z-index: 2;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_price-container {
  margin-top: 8vh;
  transition: all .5s;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_price-container .IhgY5q_price-text {
  color: #fff;
  text-shadow: 0 .2rem #303060;
  text-align: center;
  font-family: Cees Hand;
  font-size: min(1.75rem, 3vh);
  font-weight: 400;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_price-container .IhgY5q_price {
  color: #fff;
  text-shadow: 0 .5rem #182140d4;
  justify-content: center;
  align-items: center;
  height: min(5rem, 8vh);
  font-family: Supersonic Rocketship;
  font-size: min(3.5rem, 5vh);
  font-weight: 400;
  display: flex;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_price-container .IhgY5q_price img {
  height: 73%;
  margin-right: .1rem;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_middle .IhgY5q_price-container .IhgY5q_price span {
  margin: 0 .8rem;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_bottom {
  flex-direction: column;
  flex: 1;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_bottom > button {
  text-shadow: 0 .2rem #000000d4;
  letter-spacing: .1rem;
  text-transform: uppercase;
  width: min(100%, 22rem);
  height: min(4.5rem, 8vh);
  font-family: Supersonic Rocketship;
  font-size: min(2rem, 4vh);
  font-weight: 400;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_bottom > button img {
  height: 80%;
  margin-left: .2rem;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_bottom .IhgY5q_drop-table {
  color: #fff;
  text-shadow: 0 .3rem #000;
  font-family: Cees Hand;
  font-size: min(1.5rem, 3vh);
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_bottom .IhgY5q_drop-table img {
  height: min(1.5rem, 3vh);
  margin-left: .5rem;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_back {
  color: #d2cff0;
  font-family: Cees Hand;
  font-size: 1rem;
}

.IhgY5q_chest .IhgY5q_container .IhgY5q_back img {
  height: 1rem;
  margin-left: .5rem;
}

@keyframes IhgY5q_rotating {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.75);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

@keyframes IhgY5q_appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jbNodq_chests {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.jbNodq_chests::-webkit-scrollbar {
  display: none;
}

.jbNodq_chests .jbNodq_hover:hover, .jbNodq_chests .jbNodq_chest:hover {
  cursor: pointer;
}

.jbNodq_chests .jbNodq_chest {
  border-radius: .5rem;
  flex-direction: column;
  width: 6rem;
  min-width: 6rem;
  height: 7rem;
  display: flex;
  position: relative;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container {
  pointer-events: none;
  flex: 1;
  position: relative;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_timer {
  color: #fff;
  z-index: 4;
  background: #70378b;
  border: .1rem solid #b54be7;
  border-radius: .15rem;
  width: 75%;
  height: 1rem;
  margin: auto;
  font-family: Cees Hand;
  font-size: .8rem;
  display: flex;
  position: absolute;
  top: -.5rem;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 .1rem #2f0b4ac7;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_timer .jbNodq_countdown {
  flex: 1;
  justify-content: center;
  margin-top: .25rem;
  display: flex;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_badge {
  text-transform: uppercase;
  z-index: 3;
  font-size: .5rem;
  position: absolute;
  top: 25%;
  left: 10%;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_light, .jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_image {
  height: auto;
  margin: auto;
  position: absolute;
  top: .25rem;
  left: 0;
  right: 0;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_light {
  opacity: .5;
  z-index: 1;
  width: 100%;
  animation: 3s linear infinite jbNodq_rotating;
  top: -.3rem;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_image {
  z-index: 2;
  height: 80%;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_open-now {
  color: #65ffed;
  text-shadow: 0 .1rem #182140d4;
  z-index: 4;
  justify-content: center;
  align-items: end;
  height: 100%;
  margin-bottom: .2rem;
  font-family: Supersonic Rocketship;
  font-size: .8rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.jbNodq_chests .jbNodq_chest .jbNodq_chest-container .jbNodq_open-slot {
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  text-shadow: 0 .1rem #0b77b4;
  z-index: 4;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: .5rem;
  font-family: Supersonic Rocketship;
  font-size: .8rem;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  position: relative;
}

.jbNodq_chests .jbNodq_chest .jbNodq_price {
  color: #fff;
  text-shadow: 0 .1rem #182140d4;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  font-family: Supersonic Rocketship;
  font-size: .85rem;
  font-weight: 400;
  display: flex;
}

.jbNodq_chests .jbNodq_chest .jbNodq_price img {
  height: 73%;
  margin-right: .1rem;
}

.jbNodq_chests .jbNodq_chest .jbNodq_price span {
  margin: 0 .1rem;
}

.jbNodq_chests .jbNodq_chest.jbNodq_empty {
  box-sizing: border-box;
  background: #261435;
  border: 1px solid #2898ff36;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #00a3ff, 0 3px #4e1f89;
}

.jbNodq_chests .jbNodq_chest.jbNodq_empty:before {
  content: "";
  border: 2px solid #00a3ff;
  border-radius: .5rem;
  position: absolute;
  inset: 0;
}

.jbNodq_chests .jbNodq_chest.jbNodq_empty:hover:before {
  border: 2px solid #66c8ff;
}

.jbNodq_chests .jbNodq_chest.jbNodq_empty:hover .jbNodq_chest-container .jbNodq_image {
  opacity: 1;
}

.jbNodq_chests .jbNodq_chest.jbNodq_empty .jbNodq_chest-container .jbNodq_image {
  opacity: .8;
  height: 60%;
  top: 0;
  bottom: 0;
}

.jbNodq_chests .jbNodq_chest.jbNodq_violet {
  background: linear-gradient(#ce8fff, #7944ea);
  border: .4px solid #c985ff;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #e992ff, 0 3px #4e1f89;
}

.jbNodq_chests .jbNodq_chest.jbNodq_violet:hover {
  background: linear-gradient(#d7a3ff, #8656ec);
}

.jbNodq_chests .jbNodq_chest.jbNodq_yellow {
  background: linear-gradient(#fed139, #ffe75d, #fee45d, #ffc555);
  border: .4px solid #ffd439;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #ffed92, 0 3px #a55d08;
}

.jbNodq_chests .jbNodq_chest.jbNodq_yellow:hover {
  background: linear-gradient(#feda62, #ffed86, #feeb86, #ffd37e);
}

.jbNodq_chests .jbNodq_chest.jbNodq_pink {
  background: linear-gradient(#ff8fc5, #fb98c8, #ff8fc5);
  border: .4px solid #ff8fc5;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #fca5cf, 0 3px #db43a3;
}

.jbNodq_chests .jbNodq_chest.jbNodq_pink:hover {
  background: linear-gradient(#ffa3d0, #fcacd2, #ffa3d0);
}

.jbNodq_chests .jbNodq_chest.jbNodq_green {
  background: linear-gradient(#62971e, #78a63d, #7ca842, #62971e);
  border: .4px solid #62971e;
  box-shadow: 0 4px #00000040, inset 0 4px 7px #89b154, 0 3px #165124;
}

.jbNodq_chests .jbNodq_chest.jbNodq_green:hover {
  background: linear-gradient(#6da821, #83b542, #87b748, #6da821);
}

.jbNodq_selected-chest {
  z-index: 11;
}

@keyframes jbNodq_rotating {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.75);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

.chAQta_weekly-prize-menu .chAQta_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.chAQta_weekly-prize-menu .chAQta_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.chAQta_weekly-prize-menu .chAQta_title img:hover {
  cursor: pointer;
}

.chAQta_weekly-prize-menu .chAQta_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.chAQta_weekly-prize-menu .chAQta_content button img {
  height: 100%;
  margin-right: .5rem;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder {
  width: 100%;
  padding: 0 1rem;
  display: flex;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_image, .chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_button-container, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_image, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_button-container {
  flex: 1;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_image, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_image {
  max-width: 50%;
  height: 8rem;
  position: relative;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_image img, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_image img {
  height: 100%;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_button-container, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_button-container {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: relative;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_button-container button, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_button-container button {
  width: 100%;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_public .chAQta_button-container button img, .chAQta_weekly-prize-menu .chAQta_content .chAQta_holder .chAQta_button-container button img {
  height: 1rem;
}

.chAQta_weekly-prize-menu .chAQta_content .chAQta_buy {
  width: 100%;
  max-width: 18rem;
}

.Z5WCxW_battle-tab {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5rem 0 8rem;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_player, .Z5WCxW_battle-tab .Z5WCxW_monster, .Z5WCxW_battle-tab .Z5WCxW_particles {
  z-index: 5;
  pointer-events: none;
  position: absolute;
}

.Z5WCxW_battle-tab .Z5WCxW_player {
  z-index: 7;
  height: 30%;
  bottom: 35%;
  left: 0;
  right: 0;
}

.Z5WCxW_battle-tab .Z5WCxW_monster {
  width: 25%;
  height: 25%;
  bottom: 50%;
  left: 10%;
}

.Z5WCxW_battle-tab .Z5WCxW_particles {
  z-index: 5;
  opacity: .5;
  bottom: 35%;
  left: 0;
  right: 0;
}

.Z5WCxW_battle-tab .Z5WCxW_battle {
  z-index: 7;
  filter: drop-shadow(0 0 14px #da71febd);
  opacity: 0;
  width: 70%;
  margin-top: auto;
  position: relative;
}

.Z5WCxW_battle-tab .Z5WCxW_battle:hover {
  cursor: pointer;
}

.Z5WCxW_battle-tab .Z5WCxW_battle.Z5WCxW_bounce {
  animation: 1s .25s forwards Z5WCxW_bounceIn;
}

.Z5WCxW_battle-tab .Z5WCxW_battle.Z5WCxW_pulse {
  opacity: 1;
  animation: 5s infinite Z5WCxW_pulseButton;
}

.Z5WCxW_battle-tab .Z5WCxW_chests {
  z-index: 7;
  padding: 1rem;
  position: relative;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal .Z5WCxW_icon {
  height: 5rem;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal .Z5WCxW_title {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px #000;
  letter-spacing: .66px;
  text-transform: uppercase;
  font-family: Supersonic Rocketship;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal .Z5WCxW_description {
  color: #fff;
  text-shadow: 0 1px #000;
  letter-spacing: -.8px;
  font-family: More Sugar;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal .Z5WCxW_rewards {
  text-align: center;
  color: #fff;
  text-shadow: 0 1px #000;
  letter-spacing: .96px;
  align-items: center;
  gap: .5rem;
  font-family: Supersonic Rocketship;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_daily-rewards-modal .Z5WCxW_rewards img {
  height: 2rem;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line {
  box-sizing: border-box;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex: 1;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile .Z5WCxW_icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: .25rem;
  width: 3rem;
  height: 3rem;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile .Z5WCxW_content {
  flex-direction: column;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile .Z5WCxW_content .Z5WCxW_title {
  color: #fff;
  text-align: center;
  text-shadow: 0 2px #000;
  letter-spacing: .45px;
  text-transform: uppercase;
  font-family: Supersonic Rocketship;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile .Z5WCxW_content .Z5WCxW_description {
  color: #fff;
  text-shadow: 0 1px #000;
  text-align: center;
  align-items: center;
  gap: .25rem;
  font-family: More Sugar;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.Z5WCxW_battle-tab .Z5WCxW_top-line .Z5WCxW_tile .Z5WCxW_content .Z5WCxW_description img {
  height: 1rem;
}

@keyframes Z5WCxW_pulseButton {
  40% {
    filter: drop-shadow(0 0 14px #da71febd);
    transform: scale3d(1, 1, 1);
  }

  50% {
    filter: drop-shadow(0 0 20px #da71febd);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  60% {
    filter: drop-shadow(0 0 14px #da71febd);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes Z5WCxW_bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.STK3LW_daily-rewards .STK3LW_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.STK3LW_daily-rewards .STK3LW_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.STK3LW_daily-rewards .STK3LW_title img:hover {
  cursor: pointer;
}

.STK3LW_daily-rewards .STK3LW_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  display: flex;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line {
  justify-content: space-between;
  align-items: center;
  width: 80%;
  display: flex;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line.STK3LW_end {
  justify-content: end;
  padding: 1rem 3rem 1rem 0;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_day {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: .48px;
  text-transform: uppercase;
  width: 3rem;
  font-family: Supersonic Rocketship;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle {
  background: #fd80e940;
  border: 1px solid #db43a340;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
  position: relative;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle.STK3LW_active {
  border: 1px solid var(--12, #db43a3);
  background: #fd80e940;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle.STK3LW_active .STK3LW_label {
  padding: .4rem;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle img {
  z-index: 1;
  width: 60%;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle img.STK3LW_gems {
  width: 80%;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle .STK3LW_label {
  z-index: 1;
  color: #fff;
  background-color: #db43a3;
  border-radius: 5rem;
  justify-content: center;
  align-items: center;
  padding: .4rem .4rem .1rem;
  font-family: Cees Hand;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  position: absolute;
  bottom: -12px;
  right: -12px;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_circle .STK3LW_shinny {
  z-index: 0;
  mix-blend-mode: color-dodge;
  width: 160%;
  height: 160%;
  position: absolute;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_separator-h {
  background-color: #db43a340;
  width: 1rem;
  height: 1px;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_separator-h.STK3LW_active {
  background-color: #db43a3;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_separator-h.STK3LW_hidden {
  background-color: #0000;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_separator-v {
  background-color: #db43a340;
  width: 1px;
  height: 1rem;
}

.STK3LW_daily-rewards .STK3LW_content .STK3LW_calendar .STK3LW_line .STK3LW_separator-v.STK3LW_active {
  background-color: #db43a3;
}

.BhWfQW_how-to-play .BhWfQW_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.BhWfQW_how-to-play .BhWfQW_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.BhWfQW_how-to-play .BhWfQW_title img:hover {
  cursor: pointer;
}

.BhWfQW_how-to-play .BhWfQW_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden;
}

.BhWfQW_how-to-play .BhWfQW_content .BhWfQW_subtitle {
  color: #fff;
  font-family: More Sugar;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.BhWfQW_how-to-play .BhWfQW_content .BhWfQW_tuto {
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  display: flex;
}

.BhWfQW_how-to-play .BhWfQW_content .BhWfQW_tuto .BhWfQW_container {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.BhWfQW_how-to-play .BhWfQW_content .BhWfQW_tuto .BhWfQW_container .BhWfQW_text {
  flex: 1;
}

.BhWfQW_how-to-play .BhWfQW_content .BhWfQW_tuto .BhWfQW_container img {
  height: 3.5rem;
}

.BhWfQW_how-to-play .BhWfQW_content button {
  width: min(12rem, 100%);
}

.kOiuDa_confirm-clash .kOiuDa_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.kOiuDa_confirm-clash .kOiuDa_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.kOiuDa_confirm-clash .kOiuDa_title img:hover {
  cursor: pointer;
}

.kOiuDa_confirm-clash .kOiuDa_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.kOiuDa_confirm-clash .kOiuDa_content button img {
  height: 60%;
  margin-right: .5rem;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: 13px;
  align-items: center;
  gap: 2rem;
  width: 55%;
  min-width: 12rem;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance img {
  width: auto;
  height: 80%;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance .kOiuDa_description {
  color: #fff;
  flex-direction: column;
  gap: .5rem;
  font-family: Cees Hand;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance .kOiuDa_description div {
  text-shadow: 0 2px #000000d4;
  letter-spacing: .84px;
  font-family: Supersonic Rocketship;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance-entries {
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance-entries .kOiuDa_balance-entry {
  color: #fff;
  text-shadow: 0 2.605px #000;
  align-items: center;
  gap: .5rem;
  font-family: Supersonic Rocketship;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_balance-entries .kOiuDa_balance-entry img {
  height: 2rem;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_cost {
  flex-direction: column;
  gap: .5rem;
  padding: 1rem 0;
  display: flex;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_buttons {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_buttons button {
  width: min(100%, 18rem);
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_buttons .kOiuDa_free {
  font-size: 1.2rem;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_buttons .kOiuDa_free > img {
  height: 140%;
}

.kOiuDa_confirm-clash .kOiuDa_content .kOiuDa_buttons .kOiuDa_free > div {
  flex: 1;
}

.kOiuDa_confirm-clash .kOiuDa_gems {
  width: min(100%, 18rem);
}

.kOiuDa_confirm-clash .kOiuDa_gems .kOiuDa_error {
  color: #ff6262;
}

.kOiuDa_confirm-clash .kOiuDa_gems img {
  height: 100%;
  margin-right: 1rem;
}

.Gccroq_buy-energy .Gccroq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.Gccroq_buy-energy .Gccroq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.Gccroq_buy-energy .Gccroq_title img:hover {
  cursor: pointer;
}

.Gccroq_buy-energy .Gccroq_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_energy {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: 13px;
  align-items: center;
  gap: 2rem;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_energy img {
  width: auto;
  height: 80%;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_energy .Gccroq_description {
  color: #fff;
  flex-direction: column;
  gap: .5rem;
  font-family: Cees Hand;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_energy .Gccroq_description div {
  text-shadow: 0 2px #000000d4;
  letter-spacing: .84px;
  font-family: Supersonic Rocketship;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_gems, .Gccroq_buy-energy .Gccroq_content .Gccroq_vessel {
  width: min(100%, 18rem);
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_gems img, .Gccroq_buy-energy .Gccroq_content .Gccroq_vessel img {
  height: 160%;
  margin-right: 1rem;
}

.Gccroq_buy-energy .Gccroq_content .Gccroq_gems img {
  height: 100%;
}

.ibB7Uq_confirm-game .ibB7Uq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.ibB7Uq_confirm-game .ibB7Uq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.ibB7Uq_confirm-game .ibB7Uq_title img:hover {
  cursor: pointer;
}

.ibB7Uq_confirm-game .ibB7Uq_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.ibB7Uq_confirm-game .ibB7Uq_content button img {
  height: 60%;
  margin-right: .5rem;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_balance {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: 13px;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_balance .ibB7Uq_balance-buttons {
  gap: 1rem;
  display: flex;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_balance-entries {
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_balance-entries .ibB7Uq_balance-entry {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_balance-entries .ibB7Uq_balance-entry img {
  height: 2rem;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_cost {
  flex-direction: column;
  gap: .5rem;
  padding: 1rem 0;
  display: flex;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_buttons {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_buttons button {
  width: min(100%, 18rem);
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_buttons .ibB7Uq_free {
  font-size: 1.2rem;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_buttons .ibB7Uq_free > img {
  height: 140%;
}

.ibB7Uq_confirm-game .ibB7Uq_content .ibB7Uq_buttons .ibB7Uq_free > div {
  flex: 1;
}

.bjfiZG_game-type .bjfiZG_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.bjfiZG_game-type .bjfiZG_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.bjfiZG_game-type .bjfiZG_title img:hover {
  cursor: pointer;
}

.bjfiZG_game-type .bjfiZG_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.bjfiZG_game-type .bjfiZG_content button img {
  height: 100%;
  margin-right: .5rem;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash {
  width: 100%;
  padding: 0 1rem;
  display: flex;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_image, .bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_button-container, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_image, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_button-container {
  flex: 1;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_image, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_image {
  max-width: 40%;
  height: 8rem;
  position: relative;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_image img, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_image img {
  height: 100%;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_button-container, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_button-container {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: relative;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_button-container button, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_button-container button {
  width: 100%;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_single .bjfiZG_button-container button img, .bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_button-container button img {
  height: 1rem;
}

.bjfiZG_game-type .bjfiZG_content .bjfiZG_clash .bjfiZG_gems {
  flex-direction: column;
  font-size: 1.2rem;
}

.fGF89a_loadout .fGF89a_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.fGF89a_loadout .fGF89a_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.fGF89a_loadout .fGF89a_title img:hover {
  cursor: pointer;
}

.fGF89a_loadout .fGF89a_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
}

.fGF89a_loadout .fGF89a_content button img {
  height: 100%;
  margin-right: .5rem;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  gap: 2rem;
  width: 100%;
  padding-bottom: .1rem;
  display: grid;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list.fGF89a_info {
  color: #fff;
  text-align: center;
  text-shadow: 0 2.25px #000000d4;
  letter-spacing: .945px;
  text-transform: uppercase;
  font-family: Supersonic Rocketship;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list.fGF89a_details {
  text-align: left;
  color: #fff;
  font-family: Cees Hand;
  font-size: 16.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile {
  aspect-ratio: 1;
  opacity: .8;
  justify-content: center;
  align-items: end;
  display: flex;
  position: relative;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile:hover:not(.fGF89a_locked) {
  cursor: pointer;
  opacity: 1;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile.fGF89a_locked {
  pointer-events: none;
  cursor: not-allowed;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_buy {
  z-index: 10;
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  inset: 0;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_buy button {
  height: 1.5rem;
  padding: 0;
  font-size: 1rem;
  position: absolute;
  bottom: .5rem;
  left: .5rem;
  right: .5rem;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_bg {
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/square-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  position: absolute;
  inset: 0;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_info {
  z-index: 1;
  color: #fff;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: 1px;
  align-items: center;
  gap: .25rem;
  height: 2rem;
  font-family: Supersonic Rocketship;
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.fGF89a_loadout .fGF89a_content .fGF89a_list .fGF89a_tile .fGF89a_info img {
  height: 1.2rem;
}

.eKIoIq_game-mode .eKIoIq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.eKIoIq_game-mode .eKIoIq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.eKIoIq_game-mode .eKIoIq_title img:hover {
  cursor: pointer;
}

.eKIoIq_game-mode .eKIoIq_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.eKIoIq_game-mode .eKIoIq_content button img {
  height: 100%;
  margin-right: .5rem;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi {
  width: 100%;
  padding: 0 1rem;
  display: flex;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_image, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_button-container, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_image, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_button-container {
  flex: 1;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_image, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_image {
  max-width: 40%;
  height: 8rem;
  position: relative;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_image img, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_image img {
  height: 100%;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_button-container, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_button-container {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: relative;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_button-container button, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_button-container button {
  width: 100%;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_single .eKIoIq_button-container button img, .eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_button-container button img {
  height: 1rem;
}

.eKIoIq_game-mode .eKIoIq_content .eKIoIq_multi .eKIoIq_gems {
  flex-direction: column;
  font-size: 1.2rem;
}

.lnvG6q_create-room-modal .lnvG6q_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.lnvG6q_create-room-modal .lnvG6q_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.lnvG6q_create-room-modal .lnvG6q_title img:hover {
  cursor: pointer;
}

.lnvG6q_create-room-modal .lnvG6q_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 80vh;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.lnvG6q_create-room-modal .lnvG6q_content button img {
  height: 100%;
  margin-right: .5rem;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form input {
  width: 100%;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_entry .lnvG6q_title {
  color: #db43a3;
  text-align: left;
  font-family: Cees Hand;
  font-size: .8rem;
  font-weight: 700;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button {
  gap: 1rem;
  width: 100%;
  display: flex;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button > div {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 3rem;
  display: flex;
  position: relative;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button > div:hover {
  cursor: pointer;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button > div.lnvG6q_selected .lnvG6q_bg {
  filter: brightness(0) saturate() invert(44%) sepia(27%) saturate(5020%) hue-rotate(296deg) brightness(89%) contrast(91%);
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button > div .lnvG6q_bg {
  box-sizing: border-box;
  z-index: 1;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/multi-button.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  inset: 0;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_form .lnvG6q_multi-button > div .lnvG6q_text {
  z-index: 2;
}

.lnvG6q_create-room-modal .lnvG6q_content .lnvG6q_create-room-button {
  width: 100%;
}

.wVM9MW_lobby .wVM9MW_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.wVM9MW_lobby .wVM9MW_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.wVM9MW_lobby .wVM9MW_title img:hover {
  cursor: pointer;
}

.wVM9MW_lobby .wVM9MW_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 80vh;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

.wVM9MW_lobby .wVM9MW_content button img {
  height: 100%;
  margin-right: .5rem;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  width: 100%;
  display: flex;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list h2 {
  text-align: center;
  margin-top: 0;
  font-weight: normal;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_status {
  text-align: center;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  display: flex;
  overflow-y: auto;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers {
  display: flex;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers > * {
  color: #db43a3;
  text-align: left;
  font-family: Cees Hand;
  font-size: .7rem;
  font-weight: 700;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers .wVM9MW_header-name {
  flex: .4;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers .wVM9MW_header-players, .wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers .wVM9MW_header-ping, .wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_headers .wVM9MW_header-empty {
  flex: .2;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room {
  box-sizing: border-box;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: .5rem;
  display: flex;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room > div {
  align-items: center;
  display: flex;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room .wVM9MW_room-name {
  flex: .4;
  margin-bottom: 4px;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room .wVM9MW_room-players, .wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room .wVM9MW_room-ping, .wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room .wVM9MW_room-button {
  flex: .2;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_rooms .wVM9MW_room .wVM9MW_room-button button {
  width: 100%;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_actions {
  gap: 1rem;
  display: flex;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_actions .wVM9MW_exit-button {
  color: #333;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_actions .wVM9MW_exit-button:hover:not(:disabled), .wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_actions .wVM9MW_exit-button.wVM9MW_selected {
  background-color: #3331 !important;
}

.wVM9MW_lobby .wVM9MW_content .wVM9MW_room-list .wVM9MW_actions .wVM9MW_create-room-button {
  flex: 1;
}

.brmfia_room-details {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.brmfia_room-details .brmfia_room-info {
  background: linear-gradient(#b06fe3fa 0%, #713fdb 100%);
  border-radius: .5rem;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

.brmfia_room-details .brmfia_room-info > div {
  text-align: left;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.brmfia_room-details .brmfia_room-info > div > div {
  font-family: Cees Hand;
  font-size: 1.1rem;
}

.brmfia_room-details .brmfia_room-info > div > div:first-child {
  color: #ecd3ff;
  font-size: .9rem;
}

.brmfia_room-details .brmfia_players {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.brmfia_room-details .brmfia_players .brmfia_player {
  box-sizing: border-box;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem;
  display: flex;
}

.brmfia_room-details .brmfia_players .brmfia_player .brmfia_player-name {
  text-align: left;
  flex: 1;
}

.brmfia_room-details .brmfia_players .brmfia_player .brmfia_player-ready.brmfia_ready {
  color: #00ff47;
}

.brmfia_room-details .brmfia_timer {
  text-align: center;
}

.brmfia_room-details .brmfia_actions {
  gap: 1rem;
  display: flex;
}

.brmfia_room-details .brmfia_actions .brmfia_exit-button {
  color: #333;
}

.brmfia_room-details .brmfia_actions .brmfia_exit-button:hover:not(:disabled), .brmfia_room-details .brmfia_actions .brmfia_exit-button.brmfia_selected {
  background-color: #3331 !important;
}

.brmfia_room-details .brmfia_actions .brmfia_ready-button {
  flex: 1;
}

._1QQwwG_character-tab {
  z-index: 5;
  background-color: #1e0f2c;
  width: 100%;
  height: 100%;
  position: relative;
}

._1QQwwG_character-tab ._1QQwwG_character {
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 11rem);
  padding-top: 5rem;
  display: flex;
  position: relative;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_title {
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px #000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-family: Cees Hand;
  font-size: 2rem;
  display: flex;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_title img {
  height: 135%;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_container {
  color: #fff;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: calc(100% - 2rem);
  height: 0;
  font-family: Cees Hand;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_container ._1QQwwG_tabs {
  color: #666;
  gap: .5rem;
  display: flex;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_container ._1QQwwG_tabs ._1QQwwG_tab {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_container ._1QQwwG_tabs ._1QQwwG_tab button {
  width: 100%;
  padding: .25rem .5rem;
  font-size: 1.1rem;
}

._1QQwwG_character-tab ._1QQwwG_character ._1QQwwG_container ._1QQwwG_player {
  pointer-events: none;
  height: 35%;
}

._9fmJSG_cosmetics-tab {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  display: flex;
  overflow: hidden auto;
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: .36px;
  text-transform: uppercase;
  gap: .5rem;
  width: 100%;
  font-family: Supersonic Rocketship;
  font-size: .75rem;
  font-weight: 400;
  display: flex;
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button > div {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: flex;
  position: relative;
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button > div:hover {
  cursor: pointer;
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button > div._9fmJSG_selected ._9fmJSG_bg {
  filter: brightness(0) saturate() invert(44%) sepia(27%) saturate(5020%) hue-rotate(296deg) brightness(89%) contrast(91%);
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button > div ._9fmJSG_bg {
  box-sizing: border-box;
  z-index: 1;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/multi-button.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

._9fmJSG_cosmetics-tab ._9fmJSG_multi-button > div ._9fmJSG_text {
  z-index: 2;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  gap: .5rem;
  height: 100%;
  padding-bottom: .1rem;
  display: grid;
  overflow: hidden auto;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile {
  aspect-ratio: 1;
  justify-content: center;
  align-items: end;
  display: flex;
  position: relative;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile:hover {
  cursor: pointer;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile ._9fmJSG_bg {
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/square-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile ._9fmJSG_image {
  background-position: 50% 35%;
  background-repeat: no-repeat;
  background-size: 50%;
  position: absolute;
  inset: 0;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile ._9fmJSG_info {
  z-index: 1;
  color: #fff;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: 1px;
  align-items: center;
  gap: .25rem;
  height: 2rem;
  font-family: Supersonic Rocketship;
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

._9fmJSG_cosmetics-tab ._9fmJSG_list ._9fmJSG_tile ._9fmJSG_info img {
  height: 1.2rem;
}

._8dyASa_statistics-tab {
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  display: grid;
  overflow: hidden auto;
}

._8dyASa_statistics-tab ._8dyASa_tile {
  cursor: pointer;
  height: 4rem;
  position: relative;
}

._8dyASa_statistics-tab ._8dyASa_tile:hover ._8dyASa_bg {
  opacity: 1;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_bg {
  filter: brightness(0) saturate() invert(15%) sepia(15%) saturate(3814%) hue-rotate(237deg) brightness(94%) contrast(97%);
  opacity: .8;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all .15s;
  position: absolute;
  inset: 0;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container {
  box-sizing: border-box;
  gap: .5rem;
  width: 100%;
  height: 100%;
  padding: .5rem;
  display: flex;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_icon {
  z-index: 1;
  background: #532a77;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 3rem;
  display: flex;
  position: relative;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_icon img {
  height: 90%;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_icon ._8dyASa_level {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  text-shadow: 0 .5px #000;
  background: #894fbc8c;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 2px 1px 1px 0;
  font-size: .8rem;
  display: flex;
  position: absolute;
  top: .2rem;
  left: .2rem;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_info {
  z-index: 1;
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.3rem;
  display: flex;
  position: relative;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_info ._8dyASa_name {
  color: #d4a4ff;
  text-shadow: none;
  white-space: nowrap;
  font-family: Cees Hand;
  font-size: 1rem;
}

._8dyASa_statistics-tab ._8dyASa_tile ._8dyASa_container ._8dyASa_info ._8dyASa_value {
  color: #fff;
  text-shadow: 0 .5px #000;
  font-family: Supersonic Rocketship;
  font-size: 1.2rem;
  font-weight: 400;
}

.TYRSWa_upgrade .TYRSWa_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.TYRSWa_upgrade .TYRSWa_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.TYRSWa_upgrade .TYRSWa_title img:hover {
  cursor: pointer;
}

.TYRSWa_upgrade .TYRSWa_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

.TYRSWa_upgrade .TYRSWa_content button {
  width: min(100%, 18rem);
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_arrow-down {
  height: 2rem;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  height: 4rem;
  position: relative;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_bg {
  filter: brightness(0) saturate() invert(23%) sepia(52%) saturate(1527%) hue-rotate(243deg) brightness(92%) contrast(94%);
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container {
  box-sizing: border-box;
  gap: .5rem;
  width: 100%;
  height: 100%;
  padding: .5rem;
  display: flex;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_icon {
  z-index: 1;
  background: #532a77;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 3rem;
  display: flex;
  position: relative;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_icon img {
  height: 90%;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_icon .TYRSWa_level {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  text-shadow: 0 .5px #000;
  background: #894fbc8c;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 2px 1px 1px 0;
  font-size: .8rem;
  display: flex;
  position: absolute;
  top: .2rem;
  left: .2rem;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_info {
  letter-spacing: 1px;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.3rem;
  display: flex;
  position: relative;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_info .TYRSWa_name {
  color: #d4a4ff;
  text-shadow: none;
  white-space: nowrap;
  font-family: Cees Hand;
  font-size: 1rem;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_tile .TYRSWa_container .TYRSWa_info .TYRSWa_value {
  color: #fff;
  text-shadow: 0 .5px #000;
  font-family: Supersonic Rocketship;
  font-size: 1.2rem;
  font-weight: 400;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_gems {
  gap: 1rem;
  width: min(100%, 18rem);
  font-size: 1.2rem;
  display: flex;
  position: relative;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_gems .TYRSWa_error {
  color: #ff6262;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_gems img {
  height: 100%;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_gems > div {
  flex: 1;
}

.TYRSWa_upgrade .TYRSWa_content .TYRSWa_not-enough-gems {
  color: #d2cff0;
  font-family: Cees Hand;
  font-size: 1rem;
}

._8cr78a_buy-or-upgrade ._8cr78a_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

._8cr78a_buy-or-upgrade ._8cr78a_title img:hover {
  cursor: pointer;
}

._8cr78a_buy-or-upgrade ._8cr78a_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

._8cr78a_buy-or-upgrade ._8cr78a_content button {
  width: min(100%, 18rem);
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_arrow-down {
  height: 2rem;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 7rem;
  display: flex;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon img {
  z-index: 1;
  width: 100%;
  max-width: 7rem;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  rotate: -15deg;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_shadow {
  opacity: .5;
  background: #8e58c0;
  border-radius: 100%;
  width: 136px;
  height: 36.615px;
  margin: 17% auto auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-1, ._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-2, ._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-3, ._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-4 {
  color: #d19cff78;
  text-shadow: none;
  font-family: More Sugar;
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-1 {
  top: 10%;
  left: 10%;
  rotate: 15deg;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-2 {
  bottom: 10%;
  left: 12%;
  rotate: -18deg;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-3 {
  top: 11%;
  right: 12%;
  rotate: -13deg;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_weapon ._8cr78a_pew-pew-4 {
  bottom: 11%;
  right: 11%;
  rotate: 12deg;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats {
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  max-width: 20rem;
  display: flex;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_title {
  color: #fff;
  font-family: Cees Hand;
  font-size: 1rem;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid {
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  display: grid;
  overflow: hidden auto;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile {
  height: 3rem;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile:hover ._8cr78a_bg {
  opacity: 1;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_bg {
  opacity: .8;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all .15s;
  position: absolute;
  inset: 0;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container {
  box-sizing: border-box;
  gap: .5rem;
  width: 100%;
  height: 100%;
  padding: .5rem;
  display: flex;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_icon {
  z-index: 1;
  background: #532a77;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_icon img {
  width: 90%;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_info {
  z-index: 1;
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.3rem;
  display: flex;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_info ._8cr78a_name {
  color: #d4a4ff;
  text-shadow: none;
  white-space: nowrap;
  font-family: Cees Hand;
  font-size: 1rem;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_info ._8cr78a_value {
  color: #fff;
  text-shadow: 0 .5px #000;
  font-family: Supersonic Rocketship;
  font-size: 1.1rem;
  font-weight: 400;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_stats ._8cr78a_grid ._8cr78a_tile ._8cr78a_container ._8cr78a_info ._8cr78a_value span {
  color: #86ff8b;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_gems {
  gap: 1rem;
  width: min(100%, 18rem);
  font-size: 1rem;
  display: flex;
  position: relative;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_gems ._8cr78a_error {
  color: #ff6262;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_gems img {
  height: 100%;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_gems > div {
  flex: 1;
}

._8cr78a_buy-or-upgrade ._8cr78a_content ._8cr78a_not-enough-gems {
  color: #d2cff0;
  font-family: Cees Hand;
  font-size: 1rem;
}

.Soz96q_weapons-tab {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  display: flex;
  overflow: hidden auto;
}

.Soz96q_weapons-tab .Soz96q_multi-button {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: .36px;
  text-transform: uppercase;
  gap: .5rem;
  width: 100%;
  font-family: Supersonic Rocketship;
  font-size: .75rem;
  font-weight: 400;
  display: flex;
}

.Soz96q_weapons-tab .Soz96q_multi-button > div {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: flex;
  position: relative;
}

.Soz96q_weapons-tab .Soz96q_multi-button > div:hover {
  cursor: pointer;
}

.Soz96q_weapons-tab .Soz96q_multi-button > div.Soz96q_selected .Soz96q_bg {
  filter: brightness(0) saturate() invert(44%) sepia(27%) saturate(5020%) hue-rotate(296deg) brightness(89%) contrast(91%);
}

.Soz96q_weapons-tab .Soz96q_multi-button > div .Soz96q_bg {
  box-sizing: border-box;
  z-index: 1;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/multi-button.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.Soz96q_weapons-tab .Soz96q_multi-button > div .Soz96q_text {
  z-index: 2;
}

.Soz96q_weapons-tab .Soz96q_list {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  gap: .5rem;
  height: 100%;
  padding-bottom: .1rem;
  display: grid;
  overflow: hidden auto;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile {
  aspect-ratio: 1;
  justify-content: center;
  align-items: end;
  display: flex;
  position: relative;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile:hover {
  cursor: pointer;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile.Soz96q_locked {
  opacity: .4;
  pointer-events: none;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile .Soz96q_bg {
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/square-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile .Soz96q_image {
  background-position: 50% 35%;
  background-repeat: no-repeat;
  background-size: 75%;
  position: absolute;
  inset: 0;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile .Soz96q_info {
  z-index: 1;
  color: #fff;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: 1px;
  align-items: center;
  gap: .25rem;
  height: 2rem;
  font-family: Supersonic Rocketship;
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.Soz96q_weapons-tab .Soz96q_list .Soz96q_tile .Soz96q_info img {
  height: 1.2rem;
}

.tH0mnG_clash-tab {
  z-index: 5;
  background-color: #1e0f2c99;
  width: 100%;
  height: 100%;
  position: relative;
}

.tH0mnG_clash-tab .tH0mnG_clash {
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 11rem);
  padding-top: 5rem;
  display: flex;
  position: relative;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_title {
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px #000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-family: Cees Hand;
  font-size: 1.75rem;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_title img {
  height: 135%;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_title .tH0mnG_countdown-container {
  color: #fff;
  text-shadow: 0 1px #000;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_title .tH0mnG_countdown-container img {
  height: 2rem;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_title .tH0mnG_countdown-container .tH0mnG_countdown {
  flex-direction: column;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container {
  color: #fff;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: calc(100% - 2rem);
  height: 0;
  font-family: Cees Hand;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  position: relative;
  overflow-x: auto;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_no-clash {
  text-align: center;
  width: 100%;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_sub-title {
  color: #fff;
  text-align: center;
  padding-top: 2px;
  font-family: Cees Hand;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs {
  flex-direction: column;
  gap: 1rem;
  display: flex;
  position: relative;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs .tH0mnG_overlay-button {
  z-index: 10;
  position: absolute;
  inset: 0;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs .tH0mnG_overlay-button button {
  margin: auto 4rem;
  position: absolute;
  inset: 0;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top {
  align-items: end;
  gap: 1rem;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top > div {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: .5rem;
  padding: .25rem;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top > div .tH0mnG_image {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top > div .tH0mnG_image img {
  height: 3.5rem;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top > div .tH0mnG_name {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000;
  letter-spacing: .6px;
  text-transform: uppercase;
  font-family: Supersonic Rocketship;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top > div .tH0mnG_score {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px #000;
  font-family: Cees Hand;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top .tH0mnG_first {
  background: linear-gradient(#f7ff95fa 0%, #cd7f37 100%);
  border: .678px solid #ffbf85;
  border-radius: 13.562px;
  height: 10rem;
  box-shadow: 0 6.781px #00000040, inset 0 6.781px 11.867px #fffb92, 0 5.086px #8f783f;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top .tH0mnG_second {
  background: linear-gradient(#d2d2d2fa 0%, #7e7e7e 100%);
  border: .678px solid #cecece;
  border-radius: 13.562px;
  height: 9rem;
  box-shadow: 0 6.781px #00000040, inset 0 6.781px 11.867px #fdfdfd, 0 5.086px #535353;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_clubs-top .tH0mnG_third {
  background: linear-gradient(#d98d48fa 0%, #7b4b28 100%);
  border: .678px solid #b78455;
  border-radius: 13.562px;
  height: 8rem;
  box-shadow: 0 6.781px #00000040, inset 0 6.781px 11.867px #f2ac5b, 0 5.086px #64412d;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_show-all {
  cursor: pointer;
  text-align: center;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_show-all img {
  opacity: .7;
  height: 1.2rem;
  rotate: 115deg;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_show-all img.tH0mnG_up {
  rotate: -64deg;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_tabs {
  color: #666;
  gap: .5rem;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_tabs .tH0mnG_tab {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_tabs .tH0mnG_tab button {
  width: 100%;
  padding: .25rem .5rem;
  font-size: 1.1rem;
}

.tH0mnG_clash-tab .tH0mnG_clash .tH0mnG_container .tH0mnG_player {
  pointer-events: none;
  height: 35%;
}

.tH0mnG_daily-rewards-modal {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.tH0mnG_daily-rewards-modal .tH0mnG_icon {
  height: 5rem;
}

.tH0mnG_daily-rewards-modal .tH0mnG_title {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px #000;
  letter-spacing: .66px;
  text-transform: uppercase;
  font-family: Supersonic Rocketship;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tH0mnG_daily-rewards-modal .tH0mnG_rewards {
  text-align: center;
  color: #fff;
  text-shadow: 0 1px #000;
  letter-spacing: .96px;
  align-items: center;
  gap: .5rem;
  font-family: Supersonic Rocketship;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.tH0mnG_daily-rewards-modal .tH0mnG_rewards img {
  height: 2rem;
}

.GmjSwW_club-entry {
  cursor: pointer;
  position: relative;
}

.GmjSwW_club-entry.GmjSwW_selected:before {
  opacity: 1;
  border: 1px solid #ffffffb3;
}

.GmjSwW_club-entry.GmjSwW_transparent {
  opacity: .5;
}

.GmjSwW_club-entry.GmjSwW_disabled {
  pointer-events: none;
}

.GmjSwW_club-entry:hover:not(.GmjSwW_selected):before {
  opacity: .65;
}

.GmjSwW_club-entry:before {
  opacity: .5;
  content: "";
  background: linear-gradient(#ce8ffffa 0%, #7944ea 100%);
  border: .6px solid #c985ff;
  border-radius: 14px;
  position: absolute;
  inset: 0;
  box-shadow: 0 6px #00000040, inset 0 6px 12px #e992ff, 0 5px #4e1f89;
}

.GmjSwW_club-entry .GmjSwW_details {
  align-items: center;
  gap: 1rem;
  padding: .5rem;
  display: flex;
  position: relative;
}

.GmjSwW_club-entry .GmjSwW_details img {
  height: 3rem;
}

.GmjSwW_club-entry .GmjSwW_details .GmjSwW_name {
  color: #fff;
  text-shadow: 0 1.5px #000;
  letter-spacing: .54px;
  text-transform: uppercase;
  padding-top: 4px;
  font-family: Supersonic Rocketship;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.GmjSwW_club-entry .GmjSwW_details .GmjSwW_members {
  color: #fff;
  text-shadow: 0 1px #000;
  margin-left: auto;
  padding-top: 4px;
  font-family: Cees Hand;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

._3efy1G_confirm-club ._3efy1G_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

._3efy1G_confirm-club ._3efy1G_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

._3efy1G_confirm-club ._3efy1G_title img:hover {
  cursor: pointer;
}

._3efy1G_confirm-club ._3efy1G_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.25rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden auto;
}

._3efy1G_confirm-club ._3efy1G_content ._3efy1G_club {
  width: 100%;
}

._3efy1G_confirm-club ._3efy1G_content ._3efy1G_text {
  color: #d2cff0;
  text-align: center;
  font-family: Cees Hand;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

._3efy1G_confirm-club ._3efy1G_content ._3efy1G_gems {
  width: min(100%, 18rem);
}

._3efy1G_confirm-club ._3efy1G_content ._3efy1G_gems img {
  height: 100%;
  margin-right: 1rem;
}

.Fl4RFq_about-tab {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  min-height: 200px;
  display: flex;
}

.Fl4RFq_about-tab .Fl4RFq_prizes-title {
  text-align: center;
  color: #fff;
  width: 100%;
  font-family: Cees Hand;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Fl4RFq_about-tab .Fl4RFq_prizes-title svg {
  cursor: pointer;
}

.Fl4RFq_about-tab .Fl4RFq_tba {
  width: 65%;
  margin: 0 auto;
}

.Fl4RFq_about-tab .Fl4RFq_prizes {
  justify-content: center;
  width: 100%;
  padding: 0;
}

.EE-BVa_leaderboard-tab {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  min-height: 200px;
  display: flex;
  overflow-y: auto;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry {
  border-radius: 0;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem .6rem;
  display: flex;
  position: relative;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry > div {
  z-index: 1;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry.EE-BVa_my .EE-BVa_bg {
  opacity: 1;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_bg {
  opacity: .4;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_rank {
  color: #fff;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_rank.EE-BVa_first {
  color: #ffe500;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_rank.EE-BVa_second {
  color: #ebebeb;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_rank.EE-BVa_third {
  color: #be6c61;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_club {
  text-align: center;
  width: 1rem;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_club img {
  height: 1.25rem;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_violet {
  color: #db43a3;
  text-align: right;
  min-width: 60px;
}

.EE-BVa_leaderboard-tab .EE-BVa_entry .EE-BVa_key {
  flex: 1;
}

.EE-BVa_leaderboard-tab .EE-BVa_list-container {
  scrollbar-width: none;
  flex: 1;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.EE-BVa_leaderboard-tab .EE-BVa_list-container::-webkit-scrollbar {
  display: none;
}

.EE-BVa_leaderboard-tab .EE-BVa_list-container .EE-BVa_list {
  flex-direction: column;
  gap: .25rem;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.EE-BVa_leaderboard-tab .EE-BVa_list-container .EE-BVa_list .EE-BVa_loader {
  color: #fff;
  grid-column: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
}

.EE-BVa_leaderboard-tab .EE-BVa_info {
  text-align: center;
  color: #666;
  font-size: 12px;
}

._9LFFya_leaderboard-tab {
  z-index: 5;
  background-color: #1e0f2c;
  width: 100%;
  height: 100%;
  position: relative;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 11rem);
  padding-top: 5rem;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_title {
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px #000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 1.2rem 1rem 1rem;
  font-family: Cees Hand;
  font-size: 2rem;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_title img {
  height: 170%;
  margin-bottom: 12px;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container {
  color: #fff;
  text-shadow: 0 2px #000;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: calc(100% - 2rem);
  height: 0;
  font-family: Cees Hand;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_info {
  text-align: center;
  color: #666;
  font-size: 12px;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry {
  border-radius: 0;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem .6rem;
  display: flex;
  position: relative;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry > div {
  z-index: 1;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry._9LFFya_my ._9LFFya_bg {
  opacity: 1;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_bg {
  opacity: .4;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_rank {
  color: #fff;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_rank._9LFFya_first {
  color: #ffe500;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_rank._9LFFya_second {
  color: #ebebeb;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_rank._9LFFya_third {
  color: #be6c61;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_entry ._9LFFya_key {
  flex: 1;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_list-container {
  scrollbar-width: none;
  flex: 1;
  display: flex;
  position: relative;
  overflow-y: auto;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_list-container::-webkit-scrollbar {
  display: none;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_list-container ._9LFFya_list {
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_list-container ._9LFFya_list ._9LFFya_loader {
  color: #fff;
  grid-column: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_my-stats {
  text-align: center;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_my-stats ._9LFFya_my-rank, ._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_my-stats ._9LFFya_my-score {
  color: #60bbf8;
  font-weight: bold;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_tabs {
  color: #666;
  gap: 1rem;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_tabs ._9LFFya_tab {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 800;
  display: flex;
}

._9LFFya_leaderboard-tab ._9LFFya_leaderboard ._9LFFya_container ._9LFFya_tabs ._9LFFya_tab button {
  width: 100%;
}

.n7DsxG_previous-week-tab {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  display: flex;
  overflow: hidden auto;
}

.n7DsxG_previous-week-tab .n7DsxG_rewards {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: .96px;
  text-transform: uppercase;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  font-family: Supersonic Rocketship;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  overflow-y: auto;
}

.n7DsxG_previous-week-tab .n7DsxG_rewards .n7DsxG_reward {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  display: flex;
}

.n7DsxG_previous-week-tab .n7DsxG_rewards .n7DsxG_reward .n7DsxG_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .25rem;
  width: 5rem;
  height: 5rem;
}

.n7DsxG_previous-week-tab .n7DsxG_rewards .n7DsxG_no-prizes {
  text-align: center;
}

.Z8UAsG_your-entry-tab {
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  gap: 1rem;
  display: flex;
  overflow: hidden auto;
}

.Z8UAsG_your-entry-tab .Z8UAsG_line {
  width: 100%;
  display: flex;
}

.Z8UAsG_your-entry-tab .Z8UAsG_line .Z8UAsG_entry {
  color: #fff;
  text-align: center;
  text-shadow: 0 2.542px #000000d4;
  letter-spacing: .72px;
  text-transform: uppercase;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: Supersonic Rocketship;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.Z8UAsG_your-entry-tab .Z8UAsG_line .Z8UAsG_entry img {
  height: 4rem;
}

.Z8UAsG_your-entry-tab .Z8UAsG_rewards {
  color: #fff;
  text-align: center;
  text-shadow: 0 1.5px #000000d4;
  letter-spacing: .96px;
  text-transform: uppercase;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  font-family: Supersonic Rocketship;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  overflow-y: auto;
}

.Z8UAsG_your-entry-tab .Z8UAsG_rewards .Z8UAsG_reward {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  display: flex;
}

.Z8UAsG_your-entry-tab .Z8UAsG_rewards .Z8UAsG_reward .Z8UAsG_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .25rem;
  width: 5rem;
  height: 5rem;
}

.Z8UAsG_your-entry-tab .Z8UAsG_rewards .Z8UAsG_no-prizes {
  text-align: center;
}

.js0gna_weekly-prize-tab {
  z-index: 5;
  background-color: #1e0f2c;
  width: 100%;
  height: 100%;
  position: relative;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize {
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 11rem);
  padding-top: 5rem;
  display: flex;
  position: relative;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_title {
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px #000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-family: Cees Hand;
  font-size: 1.75rem;
  display: flex;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_title.js0gna_violet-image {
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/violet-modal-title.png");
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_title img {
  height: 135%;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_title .js0gna_countdown {
  color: #fff;
  text-shadow: 0 1px #000;
  font-family: More Sugar;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_container {
  color: #fff;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: calc(100% - 2rem);
  height: 0;
  font-family: Cees Hand;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_container .js0gna_tabs {
  color: #666;
  gap: .5rem;
  display: flex;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_container .js0gna_tabs .js0gna_tab {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_container .js0gna_tabs .js0gna_tab button {
  width: 100%;
  padding: .25rem .5rem;
  font-size: 1.1rem;
}

.js0gna_weekly-prize-tab .js0gna_weekly-prize .js0gna_container .js0gna_player {
  pointer-events: none;
  height: 35%;
}

.qQfzxq_homescreen {
  flex-direction: column;
  justify-content: end;
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.qQfzxq_homescreen .qQfzxq_bg-top, .qQfzxq_homescreen .qQfzxq_bg-middle, .qQfzxq_homescreen .qQfzxq_bg-bottom {
  width: 100%;
  position: absolute;
}

.qQfzxq_homescreen .qQfzxq_bg-top {
  z-index: 1;
  background-position: center;
  background-size: cover;
  height: 50%;
  top: 0;
}

.qQfzxq_homescreen .qQfzxq_bg-middle {
  z-index: 2;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50%;
  bottom: 40%;
}

.qQfzxq_homescreen .qQfzxq_bg-bottom {
  z-index: 3;
  background-position: center;
  background-size: cover;
  height: 49%;
  bottom: 0;
}

.qQfzxq_homescreen .qQfzxq_tab {
  width: 100%;
  height: 100%;
}

._70Me4q_navigation {
  box-sizing: border-box;
  background-position-y: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: space-between;
  width: 100%;
  height: 4.8rem;
  font-size: .9rem;
  display: flex;
  position: absolute;
  bottom: 0;
}

._70Me4q_navigation ._70Me4q_hover:hover {
  cursor: pointer;
}

._70Me4q_navigation ._70Me4q_bg, ._70Me4q_navigation ._70Me4q_active-bg, ._70Me4q_navigation ._70Me4q_tabs {
  width: 100%;
  height: 100%;
  display: flex;
}

._70Me4q_navigation ._70Me4q_bg, ._70Me4q_navigation ._70Me4q_active-bg {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1px;
  position: absolute;
}

._70Me4q_navigation ._70Me4q_bg {
  z-index: 0;
}

._70Me4q_navigation ._70Me4q_active-bg {
  z-index: 1;
}

._70Me4q_navigation ._70Me4q_tabs {
  z-index: 2;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab {
  flex: 1;
  justify-content: center;
  align-items: end;
  height: 100%;
  display: flex;
  position: relative;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_battle ._70Me4q_icon {
  height: 100%;
  left: 15%;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_character ._70Me4q_icon {
  height: 75%;
  top: -.75rem;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_title {
  color: #fff;
  text-shadow: 0 .1rem #182140d4;
  visibility: hidden;
  font-family: Supersonic Rocketship;
  font-size: .9rem;
  font-weight: 400;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_icon {
  height: 80%;
  margin: auto;
  transition: all .3s;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_active ._70Me4q_arrow-left, ._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_active ._70Me4q_arrow-right {
  visibility: visible;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_active ._70Me4q_icon {
  transform: scale(1.15);
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab._70Me4q_active ._70Me4q_title {
  visibility: visible;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_arrow-left, ._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_arrow-right {
  visibility: hidden;
  height: 22%;
  position: absolute;
  top: 35%;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_arrow-left {
  top: 35%;
  left: 15%;
}

._70Me4q_navigation ._70Me4q_tabs ._70Me4q_tab ._70Me4q_arrow-right {
  right: 15%;
  transform: scaleX(-1);
}

.sQTtSa_pause-menu .sQTtSa_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.sQTtSa_pause-menu .sQTtSa_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.sQTtSa_pause-menu .sQTtSa_title img:hover {
  cursor: pointer;
}

.sQTtSa_pause-menu .sQTtSa_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: More Sugar;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden;
}

.sQTtSa_pause-menu .sQTtSa_content .sQTtSa_sound {
  box-sizing: border-box;
  color: #d2cff0;
  justify-content: center;
  gap: 1rem;
  font-size: 1.5rem;
  display: flex;
}

.sQTtSa_pause-menu .sQTtSa_content .sQTtSa_sound:hover {
  cursor: pointer;
}

.sQTtSa_pause-menu .sQTtSa_content .sQTtSa_sound svg.sQTtSa_enabled {
  color: #db43a3;
}

.sQTtSa_pause-menu .sQTtSa_content .sQTtSa_player {
  width: 100%;
  height: 8rem;
}

.sQTtSa_pause-menu .sQTtSa_content button {
  width: min(12rem, 100%);
}

.B1l-rG_twitter-intent-button {
  color: #fff;
  background: #000 !important;
}

.B1l-rG_twitter-intent-button:hover {
  background: #080808 !important;
}

.fnlHsq_score {
  z-index: 5;
  width: 100%;
  height: 100%;
}

.fnlHsq_score .fnlHsq_score-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: calc(100% - 6rem);
  padding-top: 5rem;
  display: flex;
  position: relative;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_title {
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 2px #000;
  background-image: url("https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-family: Cees Hand;
  font-size: 2rem;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_title img {
  height: 135%;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container {
  color: #fff;
  text-shadow: 0 2px #000;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: calc(100% - 2rem);
  height: 0;
  font-family: Cees Hand;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_top-line {
  letter-spacing: 1.5px;
  align-items: center;
  gap: 1rem;
  font-family: Supersonic Rocketship;
  font-size: 3rem;
  font-weight: 400;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_top-line > img {
  height: 4rem;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_top-line > button {
  width: 5rem;
  margin-left: auto;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line {
  flex: 1;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_gems {
  letter-spacing: .66px;
  text-transform: uppercase;
  align-items: center;
  gap: 1rem;
  font-family: Supersonic Rocketship;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_gems img {
  height: 2rem;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_no-chest {
  text-align: center;
  text-shadow: 0 2px #000000d4;
  letter-spacing: .528px;
  text-transform: uppercase;
  width: 30vh;
  font-family: Supersonic Rocketship;
  font-size: 17.6px;
  font-weight: 400;
  position: relative;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_no-chest .fnlHsq_player {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 15vh;
  position: relative;
  rotate: -90deg;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_no-chest .fnlHsq_shadow {
  opacity: .5;
  background: #ec2727;
  border-radius: 100%;
  width: 136px;
  height: 36.615px;
  margin: auto auto 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest {
  opacity: 0;
  cursor: pointer;
  border-radius: .5rem;
  flex-direction: column;
  width: min(150px, 30vw);
  height: min(150px, 30vw);
  animation: 1s .25s forwards fnlHsq_bounceIn;
  display: flex;
  position: relative;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container {
  flex: 1;
  position: relative;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container .fnlHsq_badge {
  text-transform: uppercase;
  z-index: 3;
  font-size: .5rem;
  position: absolute;
  top: 25%;
  left: 0;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container .fnlHsq_light, .fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container .fnlHsq_image {
  height: auto;
  margin: auto;
  position: absolute;
  top: .25rem;
  left: 0;
  right: 0;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container .fnlHsq_light {
  opacity: .5;
  z-index: 1;
  width: 100%;
  animation: 3s linear infinite fnlHsq_rotating;
  top: -.3rem;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_middle-line .fnlHsq_rewards .fnlHsq_chests .fnlHsq_chest .fnlHsq_chest-container .fnlHsq_image {
  z-index: 2;
  height: 100%;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_chests {
  padding-top: 1rem;
}

.fnlHsq_score .fnlHsq_score-container .fnlHsq_container .fnlHsq_skip {
  width: min(22rem, 70%);
  margin: 1rem auto 0;
}

@keyframes fnlHsq_rotating {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.75);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

@keyframes fnlHsq_bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

._0QTAvq_buy-gems ._0QTAvq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

._0QTAvq_buy-gems ._0QTAvq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

._0QTAvq_buy-gems ._0QTAvq_title img:hover {
  cursor: pointer;
}

._0QTAvq_buy-gems ._0QTAvq_content {
  color: #fff;
  text-shadow: 0 4px #201728;
  letter-spacing: 3px;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: Supersonic Rocketship;
  font-size: 30px;
  font-weight: 400;
  display: flex;
  overflow: hidden auto;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile {
  box-sizing: border-box;
  cursor: pointer;
  background: linear-gradient(#e3996ffa 0%, #fa8efc 100%);
  border-radius: 13px;
  width: 100%;
  padding: 1rem;
  display: flex;
  box-shadow: inset 0 4px 7px #e992ffdb, 0 3px #582499;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details {
  text-align: center;
  background: linear-gradient(#d3abf2fa 7.29%, #bea5f3 60.94%);
  border: 1.5px solid #6633a3;
  border-radius: 16px;
  flex-direction: column;
  gap: .5rem;
  width: 7.5rem;
  padding: .5rem;
  font-size: 20px;
  display: flex;
  box-shadow: 0 5px #00000040, inset 0 5px 8.75px #e992ffdb, 0 3.75px #582499;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems {
  height: 3rem;
  position: relative;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-1 img {
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-2 img:first-child {
  z-index: 1;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-2 img:nth-child(2) {
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-3 img:first-child {
  z-index: 1;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-3 img:nth-child(2) {
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_details ._0QTAvq_gems._0QTAvq_gems-3 img:nth-child(3) {
  height: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_price {
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: relative;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_price img {
  height: 2rem;
}

._0QTAvq_buy-gems ._0QTAvq_content ._0QTAvq_tile ._0QTAvq_price ._0QTAvq_discount {
  z-index: 10;
  background: #7399c7;
  border-radius: 1rem;
  padding: .5rem;
  font-size: 16px;
  position: absolute;
  top: .5rem;
  left: 75%;
}

.ZV7H9G_deposit-vessels .ZV7H9G_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.ZV7H9G_deposit-vessels .ZV7H9G_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.ZV7H9G_deposit-vessels .ZV7H9G_title img:hover {
  cursor: pointer;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_input .ZV7H9G_subtitle {
  text-align: left;
  color: #db43a3;
  font-family: Cees Hand;
  font-size: .85rem;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_input .ZV7H9G_amount {
  width: 100%;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_input .ZV7H9G_error {
  color: #ff6262;
  font-size: 1rem;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_description {
  color: #d2cff0;
  text-align: center;
  letter-spacing: -.05rem;
  font-family: More Sugar;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_description span {
  color: #fff;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_balance {
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  display: flex;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_balance .ZV7H9G_vessels {
  align-items: center;
  height: 100%;
  display: flex;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_balance img {
  height: 100%;
  margin-right: 1rem;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_vessel {
  margin-top: 1rem;
}

.ZV7H9G_deposit-vessels .ZV7H9G_content .ZV7H9G_vessel img {
  height: 160%;
  margin-right: 1rem;
}

.N3kflW_depositing-vessels .N3kflW_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.N3kflW_depositing-vessels .N3kflW_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.N3kflW_depositing-vessels .N3kflW_title img:hover {
  cursor: pointer;
}

.N3kflW_depositing-vessels .N3kflW_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  gap: 2rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

.N3kflW_depositing-vessels .N3kflW_content .N3kflW_total {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 2rem;
  display: flex;
}

.N3kflW_depositing-vessels .N3kflW_content .N3kflW_total img {
  height: 3rem;
}

.N3kflW_depositing-vessels .N3kflW_content .N3kflW_count {
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.N3kflW_depositing-vessels .N3kflW_content .N3kflW_count .N3kflW_label {
  background-color: #db43a3;
  border-radius: 2rem;
  padding: 1rem;
}

.N3kflW_depositing-vessels .N3kflW_content .N3kflW_done {
  margin-top: 1rem;
}

.ym65UW_twitter-link-button {
  background: #000;
}

.ym65UW_twitter-link-button:hover {
  background: #080808 !important;
}

.Nc_WTq_settings .Nc_WTq_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.Nc_WTq_settings .Nc_WTq_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.Nc_WTq_settings .Nc_WTq_title img:hover {
  cursor: pointer;
}

.Nc_WTq_settings .Nc_WTq_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  gap: 2rem;
  font-family: More Sugar;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_subtitle {
  text-align: left;
  color: #db43a3;
  font-family: Cees Hand;
  font-size: .85rem;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_sound {
  box-sizing: border-box;
  color: #d2cff0;
  justify-content: center;
  gap: 1rem;
  font-size: 1.5rem;
  display: flex;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_sound:hover {
  cursor: pointer;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_sound svg.Nc_WTq_enabled {
  color: #db43a3;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_code {
  position: relative;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_code input {
  width: 100%;
  padding-right: 6rem;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_code .Nc_WTq_submit {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
}

.Nc_WTq_settings .Nc_WTq_content .Nc_WTq_twitter {
  width: min(100%, 18rem);
  margin: auto;
}

.QaHdgW_top-bar {
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-size: .9rem;
  display: flex;
  position: absolute;
  top: 0;
}

.QaHdgW_top-bar .QaHdgW_hover:hover {
  cursor: pointer;
}

.QaHdgW_top-bar .QaHdgW_hover * {
  pointer-events: none;
}

.QaHdgW_top-bar .QaHdgW_element-left, .QaHdgW_top-bar .QaHdgW_element-right {
  flex: 1;
  gap: 1rem;
  display: flex;
}

.QaHdgW_top-bar .QaHdgW_element-left .QaHdgW_buy {
  z-index: 1;
  color: #fff;
  width: 1rem;
  height: 54%;
  font-size: 1rem;
}

.QaHdgW_top-bar .QaHdgW_element-right {
  justify-content: end;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_element {
  text-align: right;
  width: 6.5rem;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_element .QaHdgW_quantity {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: .1rem;
  padding-left: .75rem;
  padding-right: .25rem;
  display: inline-block;
  overflow: hidden;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_element .QaHdgW_icon {
  background: #7e64a466;
  border-radius: 3.746px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_element .QaHdgW_icon img {
  width: 80%;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_settings, .QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_bag {
  background: #7e64a466;
  border-radius: 3.746px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
}

.QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_settings img, .QaHdgW_top-bar .QaHdgW_element-right .QaHdgW_bag img {
  width: 80%;
}

.QaHdgW_top-bar .QaHdgW_element {
  align-items: flex-end;
  width: 5rem;
  display: flex;
  position: relative;
}

.QaHdgW_top-bar .QaHdgW_element .QaHdgW_icon {
  z-index: 1;
  justify-content: center;
  width: 30%;
  height: 95%;
  display: flex;
  position: relative;
}

.QaHdgW_top-bar .QaHdgW_element .QaHdgW_icon img {
  object-fit: contain;
}

.QaHdgW_top-bar .QaHdgW_element .QaHdgW_quantity {
  color: #fff;
  z-index: 1;
  flex: 1;
  justify-content: center;
  height: 54%;
  display: flex;
}

.QaHdgW_top-bar .QaHdgW_element svg {
  z-index: 0;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}

.QsW5AW_wallet .QsW5AW_title {
  color: #fff;
  text-shadow: 0 2px #000;
  width: 100%;
  position: relative;
}

.QsW5AW_wallet .QsW5AW_title img {
  height: 100%;
  position: absolute;
  top: -2px;
  right: 0;
}

.QsW5AW_wallet .QsW5AW_title img:hover {
  cursor: pointer;
}

.QsW5AW_wallet .QsW5AW_content {
  color: #fff;
  text-align: center;
  letter-spacing: -.05rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  font-family: More Sugar;
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
}

.QsW5AW_wallet .QsW5AW_content .QsW5AW_vessel {
  margin-top: 1rem;
}

.QsW5AW_wallet .QsW5AW_content .QsW5AW_vessel img {
  height: 160%;
  margin-right: 1rem;
}

.QsW5AW_wallet .QsW5AW_content button {
  width: min(100%, 18rem);
}

/*# sourceMappingURL=index.2615cf19.css.map */
